import { format, isToday, isYesterday } from 'date-fns';
import { DateTime } from 'luxon';

/**
 *
 * @param date - the date or date string that the time will be attached to
 * @param time - the time that will be combined with the date
 * @param timezone - the timezone which the date value should be based off of
 * @returns ISO DateString in UTC
 */
export const combineDateTimeAndTimezone = (
  date: string | Date | undefined,
  time: string | undefined,
  timezone: string | undefined,
): string | undefined => {
  if (!date || !time || !timezone) {
    return undefined;
  }

  const dt = typeof date === 'string' ? new Date(`${date} 00:00:00`) : date;
  const indexOfColon = time.indexOf(':');
  const hours = Number.parseInt(time.substring(0, indexOfColon), 10);
  const minutes = Number.parseInt(time.substring(indexOfColon + 1, indexOfColon + 3), 10);
  const isAfternoon = time.includes('PM');

  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return undefined;
  }

  const newDt = DateTime.now()
    .setZone(timezone)
    .set({
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate(),
      hour: hours + (isAfternoon ? 12 : 0),
      minute: minutes,
      second: 0,
      millisecond: 0,
    });

  return newDt.toUTC().toString();
};
/**
 *
 * @param date - the date or date string to be combined with the time
 * @param time - the time to be combined with the date
 * @returns - the ISO string of the combined date and time based on the users timezone
 */
export const combineDateAndTimeLocalTimezone = (date: string | Date, time: string): string | undefined => {
  const timeFormatRegex = /(\d{1,2}):(\d{2})\s?/;
  const timeMatch = time.match(timeFormatRegex);
  if (!timeMatch) {
    return undefined;
  }
  return new Date(date + ' ' + time).toISOString();
};

/**
 *
 * @param date - the date or date string to be compared to now
 * @returns the time difference in milliseconds
 */
export const timeSince = (date: string | Date): number => {
  const now = new Date().getTime();

  if (typeof date === 'string') {
    return new Date(date).getTime() - now;
  } else {
    return date.getTime() - now;
  }
};

/**
 *
 * @param date - date to format
 * @param formatStr - format string for date-fns to use
 * @param withTime - boolean to determine if time should be included with yesterday or today
 * @returns formatted date string that considers if it's yesterday or today
 */
export const formatWithYesterdayAndToday = (
  date: string | number | Date | undefined,
  formatStr: string,
  withTime: boolean = false,
): string => {
  if (!date) {
    return '';
  }

  if (isYesterday(date)) {
    return withTime ? `Yesterday ${format(date, 'hh:mm aaa')}` : 'Yesterday';
  }

  if (isToday(date)) {
    return withTime ? `Today ${format(date, 'hh:mm aaa')}` : 'Today';
  }

  return format(date, formatStr);
};
