export enum BHSurveyType {
  GAD7 = 'gad7',
  PHQ9 = 'phq9',
}

export enum GAD7ResponseCategory {
  LowRisk = 'No to Low Risk',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
}

export enum PHQ9ResponseCategory {
  NonOrMinimal = 'Non-Minimal',
  Mild = 'Mild',
  Moderate = 'Moderate',
  ModeratelySevere = 'Moderately Severe',
  Severe = 'Severe',
}

export enum QuestionnaireAudience {
  Patient = 'patient',
  Caregiver = 'caregiver',
  Household = 'household',
}

export enum QuestionnaireType {
  Screener = 'screener',
  BHSurvey = 'bh-survey',
  DisasterPreparedness = 'disaster-preparedness-survey',
  ConsentToTreat = 'consent-to-treat', //TODO: determine if we can use one code for all consent to treat or if we need unique
  ReleaseOfInformation = 'release-of-information',
  EndUserLicenseAgreement = 'end-user-license-agreement',
  PostVideoSurvey = 'post-video-survey',
  HixnyConsent = 'hixny-consent',
}

export enum QuestionnaireResponseCategory {
  Positive = 'positive',
  Negative = 'negative',
  SuicideRisk = 'suicide risk',
  PendingScore = 'pending score',
}
/**
 * These are the Item Types defined by FHIR standards
 */
export enum QuestionnaireItemType {
  group = 'group',
  display = 'display',
  question = 'question',
  boolean = 'boolean',
  decimal = 'decimal',
  integer = 'integer',
  date = 'date',
  dateTime = 'dateTime',
  time = 'time',
  string = 'string',
  text = 'text',
  url = 'url',
  choice = 'choice',
  openChoice = 'open-choice',
  attachment = 'attachment',
  reference = 'reference',
  quantity = 'quantity',
}
/**
 * Used to further define a display type for items of type `display`
 */
export enum QuestionnaireItemDisplayType {
  Bullet = 'bullet',
  Heading = 'heading',
}
