import React from 'react';

import { Collapse, Divider, Group, Stack, Title, Text, Box, ThemeIcon } from '@mantine/core';
import { QuestionnaireItem, QuestionnaireResponseItem } from '@medplum/fhirtypes';
import { QuestionnaireItemType } from 'const-utils/codeSystems/ImaginePediatrics';
import { ReadOnlyQuestionnaireRepeatableItem } from './QuestionnaireRepeatableItem';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { TranslationCodeMapping, translatedDisplayByCode } from 'imagine-dsl/services/questionnaireService';

interface ReadOnlyQuestionnaireRepeatableGroupProps {
  readonly item: QuestionnaireItem;
  readonly response: QuestionnaireResponseItem[];
  translatedItemMapping: TranslationCodeMapping[];
  defaultOpen?: boolean;
  withDivider?: boolean;
  previousItemTotal?: number;
}

export function ReadOnlyQuestionnaireRepeatedGroup(
  props: ReadOnlyQuestionnaireRepeatableGroupProps,
): JSX.Element | null {
  const { response, translatedItemMapping, defaultOpen, withDivider, previousItemTotal } = props;
  if (response.length === 0) {
    return null;
  }

  return (
    <>
      {response.map((responseItem) => (
        <ReadOnlyQuestionnaireGroup
          key={responseItem.id}
          item={props.item}
          response={responseItem}
          translatedItemMapping={translatedItemMapping}
          defaultOpen={defaultOpen}
          withDivider={withDivider}
          previousItemTotal={previousItemTotal}
        />
      ))}
    </>
  );
}

interface QuestionnaireGroupProps {
  readonly item: QuestionnaireItem;
  readonly response: QuestionnaireResponseItem;
  translatedItemMapping: TranslationCodeMapping[];
  defaultOpen?: boolean;
  withDivider?: boolean;
  previousItemTotal?: number;
  index?: number;
}

export function ReadOnlyQuestionnaireGroup(props: QuestionnaireGroupProps): JSX.Element | null {
  const {
    item,
    response,
    translatedItemMapping,
    defaultOpen,
    previousItemTotal,
    withDivider = true,
    index: indexProp,
  } = props;
  const [opened, { toggle }] = useDisclosure(defaultOpen ?? false);

  const itemCode = item.linkId;
  const display =
    translatedItemMapping.length > 0
      ? (translatedDisplayByCode(translatedItemMapping, itemCode) ?? item.text ?? 'Survey Group') //If a group does not have a display/text value it won't be shown
      : item.text;

  return (
    <div key={item.linkId}>
      {display && (
        <>
          {withDivider && <Divider />}
          <Group align="center" mt="md">
            {indexProp && (
              <Text ml={17} style={{ flex: 0.05 }}>
                {indexProp}.
              </Text>
            )}
            <Group style={{ flex: 0.95 }}>
              {opened ? (
                <ThemeIcon color="imagine-green" variant="transparent" mr={5} flex="0.05">
                  <IconChevronDown size={20} />
                </ThemeIcon>
              ) : (
                <ThemeIcon color="imagine-green" variant="transparent" mr={5} flex="0.05">
                  <IconChevronRight size={20} />
                </ThemeIcon>
              )}
              {!indexProp && (
                <Title order={5} onClick={() => toggle()} style={{ cursor: 'pointer', flex: 0.95 }}>
                  {display}
                </Title>
              )}
              {indexProp && (
                <Text fw={700} onClick={() => toggle()} style={{ cursor: 'pointer', flex: 0.95 }}>
                  {display}
                </Text>
              )}
            </Group>
          </Group>
        </>
      )}

      <Collapse in={opened}>
        <Stack pr={4}>
          {item.item?.map((item, index) => {
            if (item.type === QuestionnaireItemType.group) {
              return item.repeats ? (
                <Box ml={10}>
                  <ReadOnlyQuestionnaireRepeatedGroup
                    key={item.linkId}
                    item={item}
                    response={response.item?.filter((i) => i.linkId === item.linkId) ?? []}
                    translatedItemMapping={translatedItemMapping}
                  />
                </Box>
              ) : (
                <Box ml={10}>
                  <ReadOnlyQuestionnaireGroup
                    key={item.linkId}
                    item={item}
                    withDivider={false}
                    defaultOpen={true}
                    index={index + (previousItemTotal ?? 0) + 1}
                    response={response.item?.find((i) => i.linkId === item.linkId) ?? { linkId: item.linkId }}
                    translatedItemMapping={translatedItemMapping}
                  />
                </Box>
              );
            }
            return (
              <Box ml={indexProp ? 30 : 0} key={item.linkId}>
                <ReadOnlyQuestionnaireRepeatableItem
                  translatedItemMapping={translatedItemMapping}
                  item={item}
                  response={response.item?.find((i) => i.linkId === item.linkId)}
                  index={index + (previousItemTotal ?? 0)}
                />
              </Box>
            );
          })}
        </Stack>
      </Collapse>
    </div>
  );
}
