import {
  Attachment,
  Communication,
  CommunicationPayload,
  DocumentReference,
  Reference,
  Task,
} from '@medplum/fhirtypes';
import { CommunicationCategory, CommunicationMedium, Extensions, System } from 'const-utils';
import { Maybe } from 'medplum-gql';
import { ResourceBuilderParams, isOfType } from './resource';

/**
 *
 * @param communication - the communication to see if it was sent by practitioner
 * @returns true if the communication was sent by a practitioner or device
 */
export const getSentByImaginePediatrics = (communication: Maybe<Communication>): boolean => {
  if (!communication?.sender?.resource) {
    return false;
  }
  const sender = communication.sender.resource;

  return isOfType(sender, 'Device') || isOfType(sender, 'Practitioner');
};

export const getSentByPractitioner = (communication: Maybe<Communication>): boolean => {
  if (!communication?.sender?.resource) {
    return false;
  }

  const sender = communication.sender.resource;

  return isOfType(sender, 'Practitioner');
};

type Priority = 'routine' | 'urgent' | 'asap' | 'stat';
export const getPriority = ({
  isAcuteCareOnly,
  communication,
  task,
}: {
  isAcuteCareOnly: boolean;
  communication?: Partial<Communication>;
  task?: Partial<Task>;
}): Priority => {
  if (isAcuteCareOnly) {
    return 'urgent';
  }
  if (communication?.priority === 'urgent') {
    return communication.priority;
  }
  return task?.priority ?? 'routine';
};

interface ManagedFields {
  medium: CommunicationMedium;
  category?: CommunicationCategory;
  contentString?: string;
  status?: Communication['status'];
  attachment?: Attachment;
  contentReference?: Reference<DocumentReference | Task>;
  subject: NonNullable<Communication['subject']>;
  sender: NonNullable<Communication['sender']>;
}

type CreateCommunicationParams = ResourceBuilderParams<Communication, ManagedFields>;
/**
 *
 * @param args - the details of the communication to create
 * @returns a communication object with the details properly set
 */
export const buildCommunication = (args: CreateCommunicationParams): Communication => {
  const { medium, category, contentString, attachment, sent, status, contentReference, ...rest } = args;

  let payload: CommunicationPayload[];
  if (attachment) {
    payload = [{ contentString: contentString || undefined, contentAttachment: attachment }];
  } else if (contentReference) {
    payload = [{ contentString: contentString || undefined, contentReference }];
  } else {
    payload = contentString ? [{ contentString }] : [];
  }

  const categoryCoding = category ? [{ code: category, system: System.CommunicationCategory }] : undefined;
  const communicationCategory = categoryCoding ? [{ coding: categoryCoding }] : undefined;

  const communication: Communication = {
    resourceType: 'Communication',
    sent: sent ?? new Date().toISOString(),
    status: status ?? 'completed',
    medium: [
      {
        coding: [
          {
            code: medium,
            system: System.CommunicationMedium,
          },
        ],
      },
    ],
    category: communicationCategory,
    payload,
    ...rest,
  };

  return communication;
};

export const getCommunicationNoteValueForExtension = (
  communication: Communication,
  noteType: string,
): string | undefined => {
  return communication.note?.find((n) =>
    n.extension?.find(
      (ext) => (ext.url as Extensions) === Extensions.CommunicationNoteType && ext.valueCode === noteType,
    ),
  )?.text;
};
