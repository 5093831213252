import { ValueSet } from '@medplum/fhirtypes';
import { SYSTEM_URL, Concepts, codeSystemConcepts } from '../codeSystems/ImaginePediatrics';
import { System } from '../codeSystems/system';

const disengagementReasonCodes = codeSystemConcepts[Concepts.DisengagementReason].concept;

export const DisengagementReason: ValueSet = {
  resourceType: 'ValueSet',
  url: System.DisengagementReason,
  identifier: [
    {
      system: 'https://imaginepediatrics.org/fhir/ValueSet',
      value: 'disengagement-reason',
    },
  ],
  version: '0.0.1',
  name: 'Disengagement Reason',
  title: 'Disengagement Reason',
  status: 'active',
  experimental: false,
  compose: {
    include: [
      {
        system: SYSTEM_URL,
        concept: disengagementReasonCodes!.map((c) => ({ code: c.code, display: c.display })),
      },
    ],
  },
};
