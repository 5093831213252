import { formatHumanName } from '@medplum/core';
import { Coding, Practitioner, Reference } from '@medplum/fhirtypes';
import { OutreachCallDisposition, CommunicationNoteType, CallDisposition } from 'const-utils';
import { getCodingDisplayForValueSet } from 'const-utils/valueSets/selectValue';
import { GetPatientActivityQuery } from 'medplum-gql';
import { OutreachDisposition, OutreachModality } from 'const-utils/codeSystems/ImaginePediatrics';

export interface OutreachAttempt {
  senderName: string;
  senderRole: string;
  sentTime?: Date;
  outcome: string;
  phoneNumber?: string;
  modality?: OutreachModality;
  contactName?: string;
  callbackDateTimeStart?: Date;
  callbackDateTimeEnd?: Date;
  callbackTimezone?: string;
  note?: string;
  id?: string;
  basedOn?: Reference[];
}

export const formatHourWithMinutes = (hour: number, minutes: number): string => {
  const period = hour < 12 ? 'AM' : 'PM';
  return `${((hour - 1) % 12) + 1}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const parseHourWithMinutes = (hourWithMinutes: string): string => {
  const [timePart, period] = hourWithMinutes.split(' ');
  const [hour, minutes] = timePart.split(':');
  let adjustedHour = Number.parseInt(hour, 10);
  if (hour === '12' && period === 'AM') {
    adjustedHour = 0;
  } else if (hour !== '12' && period === 'PM') {
    adjustedHour += 12;
  }
  const paddedHour = adjustedHour.toString().padStart(2, '0');
  const paddedMinutes = minutes.padStart(2, '0');

  return `${paddedHour}:${paddedMinutes}`;
};

type HasOutcome = {
  outcome?: string;
};
export const isCallbackRequested = (values: HasOutcome): boolean => {
  // TODO: cleanup after cut-over to new outreach modalities and dispositions
  return (
    values.outcome === CallDisposition.CallbackRequested || values.outcome === OutreachDisposition.CallbackRequested
  );
};

export function mapOutreachQueryResult(query: GetPatientActivityQuery): OutreachAttempt[] {
  const attemptsGroomed =
    query.Patient?.outreachAttempts?.map((att) => {
      const practitioner = att?.sender?.resource as Practitioner | undefined;

      const senderName = (practitioner?.name?.[0] && formatHumanName(practitioner.name[0])) || 'Unknown';

      const senderRole =
        (att?.sender?.resource?.__typename === 'Practitioner' &&
          att.sender.resource.PractitionerRoleList?.[0]?.code?.[0]?.coding?.[0]?.display) ||
        'Unknown';

      const outcome = att?.statusReason?.coding?.[0]
        ? getCodingDisplayForValueSet(OutreachCallDisposition, att.statusReason.coding[0] as Coding)
        : 'Unknown';

      const modality: OutreachModality | undefined =
        (att?.note?.find((note) =>
          note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.OutreachModality),
        )?.text as OutreachModality | undefined) ??
        // TODO: cleanup after cut-over to new outreach modalities and dispositions
        // The typing here is a lie but it's a temporary fix to get the new modality enum in place
        // The downstream code will be aware and have a fallback until the backfill script is run
        (att?.note?.find((note) => note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.CallRoute))
          ?.text as OutreachModality | undefined);

      const phoneNumber = att?.note?.find((note) =>
        note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.PhoneNumber),
      )?.text;

      const contactName = att?.note?.find((note) =>
        note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.ContactName),
      )?.text;

      const callbackDateTimeStart = att?.note?.find((note) =>
        note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.CallbackStart),
      )?.text;
      const callbackDateTimeEnd = att?.note?.find((note) =>
        note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.CallbackEnd),
      )?.text;

      const callbackTimezone = att?.note?.find((note) =>
        note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.CallbackTimezone),
      )?.text;

      const note = att?.note?.find((note) =>
        note?.extension?.some((ext) => ext?.valueCode === CommunicationNoteType.Notes),
      )?.text;

      return {
        id: att?.id || undefined,
        senderName,
        senderRole,
        sentTime: att?.sent ? new Date(att.sent) : undefined,
        phoneNumber,
        outcome,
        modality,
        contactName,
        callbackDateTimeStart: callbackDateTimeStart ? new Date(callbackDateTimeStart) : undefined,
        callbackDateTimeEnd: callbackDateTimeEnd ? new Date(callbackDateTimeEnd) : undefined,
        callbackTimezone,
        note,
      } satisfies OutreachAttempt;
    }) || [];

  return attemptsGroomed;
}
