export enum CarePathway {
  Medical = 'M',
  CoreCare = 'CC',
  BehavioralHealth = 'BH',
  AcuteCare = 'AC',
}

export enum CarePathwayOverride {
  Medical = 'M',
  CoreCare = 'CC',
  BehavioralHealth = 'BH',
}

export const carePathwayRanking: Record<CarePathway, number> = {
  [CarePathway.Medical]: 1,
  [CarePathway.BehavioralHealth]: 1,
  [CarePathway.AcuteCare]: 1,
  [CarePathway.CoreCare]: 0,
};
export const carePathwayDisplay: Record<CarePathway, string> = {
  [CarePathway.Medical]: 'Medical',
  [CarePathway.CoreCare]: 'Core Care',
  [CarePathway.BehavioralHealth]: 'Behavioral Health (BH)',
  [CarePathway.AcuteCare]: 'Acute Care (AC)',
};

export const allowedCarePathwayOverride: Record<CarePathwayOverride, string> = {
  [CarePathway.Medical]: 'Medical',
  [CarePathway.CoreCare]: 'Core Care',
  [CarePathway.BehavioralHealth]: 'Behavioral Health (BH)',
};

export const carePathwayDeescalationReferralReasons: Record<string, string> = {
  CaregiverPreference: 'Caregiver preference',
  WellSupportedByPrimaryCareTeam: 'Well supported by primary care team',
  ImprovementInPrimaryDiagnosis: 'Improvement in primary diagnosis',
  ImprovementInUtilization: 'Improvement in utilization',
  ResolutionOfClusterDesignation:
    'Resolution of cluster designation (ie: remissions of oncological condition, curative surgery or procedure)',
  PatientIsASiblingOfAnotherPatientInTheCarePathway: `Patient is a sibling of another patient in the care pathway (ie: Patient's sibling is in medical model)`,
  HealthPlanContractChange: `Health plan contract change (ie: a contractual change with the health plan required updating the patient's care pathway)`,
};

export const carePathwayOptions = Object.entries(allowedCarePathwayOverride).map(([value, label]) => ({
  value,
  label,
}));

export enum CarePathwayReferralType {
  Data = 'data',
  Manual = 'manual',
}
