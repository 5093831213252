import React, { useState } from 'react';
import { Dialog } from '@mantine/core';
import { DraggableCore } from 'react-draggable';
import { useVideo } from './VideoProvider';

export const VideoInterfaceWrapper = ({
  children,
  activeCall,
  overflow = 'hidden',
}: {
  children: React.ReactNode;
  activeCall: boolean;
  overflow?: string;
}): JSX.Element => {
  const [position, setPosition] = useState({ bottom: 20, left: 20 });
  const { endCall } = useVideo();

  return (
    <DraggableCore
      onDrag={(_e, data) => {
        setPosition({ bottom: position.bottom - data.deltaY, left: position.left + data.deltaX });
      }}
    >
      <Dialog
        position={position}
        style={{
          display: 'flex',
          background: 'black',
          overflow: overflow,
          padding: 0,
          color: 'white',
          fontSize: 12,
          borderRadius: '10px',
          minHeight: '10rem',
          justifyContent: 'center',
        }}
        size="xl"
        opened={activeCall}
        onClose={endCall}
      >
        {children}
      </Dialog>
    </DraggableCore>
  );
};
