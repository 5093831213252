import React, { PropsWithChildren, Suspense } from 'react';
import { LoadingOverlay, AppShell as MantineAppShell, useMantineTheme, Stack } from '@mantine/core';
import { useMedplumProfile } from '@medplum/react';
import { VERTICAL_NAV_WIDTH, VERTICAL_NAV_WIDTH_COLLAPSED } from './shared/constants';
import { CollapsedVerticalNav, VerticalNav } from './nav';
import { useVerticalNav } from './nav/VerticalNavContext';
import { HorizontalNav } from './nav/HorizontalNav';

const AppShellWithHeader = ({ children }: PropsWithChildren): JSX.Element => {
  const theme = useMantineTheme();
  const profile = useMedplumProfile();
  const { opened, open, close } = useVerticalNav();

  return (
    <MantineAppShell
      navbar={{
        width: profile && opened ? VERTICAL_NAV_WIDTH : 0,
        breakpoint: 'sm',
        collapsed: { mobile: !open, desktop: !open },
      }}
      padding={0}
      styles={{
        main: {
          background: theme.colors['imagine-gray'][1],
        },
      }}
    >
      {profile && !opened && <CollapsedVerticalNav visible={!opened} toggleVisibility={open} />}
      {profile && opened && <VerticalNav open={opened} toggle={close} profile={profile} />}
      <HorizontalNav pl={opened ? VERTICAL_NAV_WIDTH + 20 : VERTICAL_NAV_WIDTH_COLLAPSED + 20} />
      <MantineAppShell.Main
        h="100%"
        pt={72}
        pb="0"
        pl={opened ? VERTICAL_NAV_WIDTH + 20 : VERTICAL_NAV_WIDTH_COLLAPSED + 20}
      >
        <Suspense fallback={<LoadingOverlay visible />}>
          {profile ? (
            <div
              style={{
                display: 'flex',
                minHeight: '100%',
                justifyContent: 'space-between',
              }}
            >
              <CollapsedVerticalNav visible={!opened} toggleVisibility={open} />
              {children}
            </div>
          ) : (
            <Stack p="xl">{children}</Stack>
          )}
        </Suspense>
      </MantineAppShell.Main>
    </MantineAppShell>
  );
};

export const AppShell = (props: PropsWithChildren): JSX.Element => {
  return <AppShellWithHeader {...props} />;
};

export default AppShell;
