import { ActionIcon, Avatar, Box, Group, Table, Text, ThemeIcon } from '@mantine/core';
import {
  IconAlertCircle,
  IconCirclePlus,
  IconEditCircle,
  IconInfoCircle,
  IconTrash,
  IconUsersGroup,
} from '@tabler/icons-react';
import React from 'react';
import ManagePodAssignmentIconAction from './ManagePodAssignmentIconAction';
import { GetPatientQuery } from 'medplum-gql';
import { extractCareTeam, extractPod } from 'imagine-dsl/utils/careTeam';
import ManageCareTeamIconAction, { ShowOptions } from './ManageCareTeamIconAction';
import { uniqBy } from 'lodash';
import { getInitials } from 'imagine-dsl/utils/strings';
import { modals } from '@mantine/modals';
import { useMedplum } from '@medplum/react';
import { ApolloQueryResult } from '@apollo/client';
import { EMPTY_DATA_PLACEHOLDER } from 'const-utils/utils/constants';

interface PatientCareTeamProps {
  patient: GetPatientQuery | undefined;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export const ClinicalTab = ({ patient, refetch }: PatientCareTeamProps) => {
  const medplum = useMedplum();

  const pod = patient && extractPod(patient);

  const internalCareTeam = patient && extractCareTeam(patient, 'internalCareTeam');
  const externalCareTeam = patient && extractCareTeam(patient, 'externalCareTeam');

  const internalMembers = uniqBy(internalCareTeam?.members || [], (m) => m.fhirReference);
  const externalMembers = uniqBy(externalCareTeam?.members || [], (m) => m.fhirReference);

  const removeMember = async (reference: string, careTeamId: string): Promise<void> => {
    const existing = await medplum.readResource('CareTeam', careTeamId);

    if (existing) {
      await medplum.updateResource({
        ...existing,
        participant: existing.participant?.filter((m) => m.member?.reference !== reference),
      });
      await refetch();
    }
  };

  const openDeleteModal = (reference: string, careTeamId: string, isPod: boolean = false): void =>
    modals.openConfirmModal({
      title: isPod ? 'Remove from Pod' : 'Remove Care Team Member',
      children: isPod ? (
        <Text size="sm">Are you sure you want to remove this patient from their pod?</Text>
      ) : (
        <Text size="sm">Are you sure you want to remove this care team member from the patient&apos;s care team?</Text>
      ),
      centered: true,
      labels: { confirm: 'Yes, remove member', cancel: 'Cancel' },
      onConfirm: () => removeMember(reference, careTeamId),
    });

  const rows = internalMembers.map((m) => {
    return (
      <Table.Tr key={m.fhirReference}>
        <Table.Td w="200px">
          <Text size="xs">{m.role || EMPTY_DATA_PLACEHOLDER}</Text>
        </Table.Td>
        <Table.Td>
          <Group>
            <Avatar alt="care-team-name" size="sm">
              {getInitials(m.name)}
            </Avatar>
            <Text size="xs">{m.name || EMPTY_DATA_PLACEHOLDER}</Text>
            <Group align="start" gap={0}>
              <ManageCareTeamIconAction
                patientId={patient!.Patient!.id!}
                internalCareTeamId={internalCareTeam?.id}
                role={m.roleCode}
                refetch={refetch}
                previousMemberId={m.fhirReference}
                show={ShowOptions.Internal}
              >
                <ThemeIcon c="imagine-green">
                  <IconEditCircle size={15} />
                </ThemeIcon>
              </ManageCareTeamIconAction>
              <ActionIcon c="status-error" onClick={() => openDeleteModal(m.fhirReference, internalCareTeam!.id)}>
                <IconTrash size={15} />
              </ActionIcon>
            </Group>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  const externalRows = externalMembers.map((m) => {
    const practitionerId = m.fhirReference.split('/')[1];
    return (
      <Table.Tr key={m.fhirReference}>
        <Table.Td w="200px">
          <Text size="xs">{m.role || EMPTY_DATA_PLACEHOLDER}</Text>
        </Table.Td>
        <Table.Td>
          <Group>
            <Avatar alt="care-team-name" size="sm">
              {getInitials(m.name)}
            </Avatar>
            <Text size="xs" flex={1}>
              {m.name || EMPTY_DATA_PLACEHOLDER}
            </Text>
            <Group align="start" gap={0}>
              <ManageCareTeamIconAction
                patientId={patient!.Patient!.id!}
                externalCareTeamId={externalCareTeam?.id}
                role={m.roleCode}
                refetch={refetch}
                previousMemberId={m.fhirReference}
                practitionerId={practitionerId}
                show={ShowOptions.External}
                disabledTooltip={m.roleCode ? undefined : 'This care team member is currently uneditable.'}
              >
                <ThemeIcon color="imagine-green">
                  <IconEditCircle size={15} />
                </ThemeIcon>
              </ManageCareTeamIconAction>
              <ActionIcon c="status-error" onClick={() => openDeleteModal(m.fhirReference, externalCareTeam!.id)}>
                <IconTrash size={15} />
              </ActionIcon>
            </Group>
          </Group>
        </Table.Td>
        <Table.Td>
          <Text size="xs">{m.association || EMPTY_DATA_PLACEHOLDER}</Text>
        </Table.Td>
        <Table.Td>
          <Text size="xs">{m.contact || EMPTY_DATA_PLACEHOLDER}</Text>
        </Table.Td>
      </Table.Tr>
    );
  });

  const hasInternalCareTeam = (internalCareTeam?.members?.length || 0) !== 0;
  const hasExternalCareTeam = (externalCareTeam?.members?.length || 0) !== 0;

  return (
    <>
      <Group mt="lg" justify="space-between" bg="imagine-green.0" p={10} style={{ borderRadius: '10px' }}>
        <Group>
          <Text size="md">Internal Care Team</Text>
          <Box h={20} ml={10} style={{ borderLeft: '1px solid var(--mantine-color-imagine-gray-6)' }} />
          <Text fw={700}>Pod:</Text>

          {pod ? (
            <Group align="center" gap={10}>
              <IconUsersGroup size={16} />
              <Text size="md">{pod.name}</Text>
              <ManagePodAssignmentIconAction
                patientId={patient!.Patient!.id!}
                careTeamId={internalCareTeam?.id}
                refetch={refetch}
                previousPodId={pod.id}
              >
                <ThemeIcon color="imagine-green">
                  <IconEditCircle size={16} />
                </ThemeIcon>
              </ManagePodAssignmentIconAction>

              <ActionIcon
                onClick={() => openDeleteModal(`CareTeam/${pod.id}`, internalCareTeam!.id, true)}
                bg="imagine-green.0"
                p={0}
                c="status-error"
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          ) : (
            <Group justify="center" gap={10}>
              <ThemeIcon c="status-error">
                <IconAlertCircle size={16} />
              </ThemeIcon>
              <Text fz="xs">No Pod Assigned</Text>
              <ManagePodAssignmentIconAction
                patientId={patient!.Patient!.id!}
                careTeamId={internalCareTeam?.id}
                refetch={refetch}
                previousPodId={undefined}
              >
                <IconEditCircle size={16} />
              </ManagePodAssignmentIconAction>
            </Group>
          )}
        </Group>
        <ManageCareTeamIconAction
          patientId={patient!.Patient!.id!}
          internalCareTeamId={internalCareTeam?.id}
          externalCareTeamId={externalCareTeam?.id}
          show={ShowOptions.Both}
          refetch={refetch}
        >
          <IconCirclePlus size={20} />
        </ManageCareTeamIconAction>
      </Group>
      {hasInternalCareTeam && (
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Role</Table.Th>
              <Table.Th>Name</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
      {!hasInternalCareTeam && (
        <Group mt={20} align="center" gap={7}>
          <ThemeIcon color="status-info">
            <IconInfoCircle size={20} />
          </ThemeIcon>
          <Text c="dimmed" fz="xs">
            This patient has not been assigned any imagine care team members.
          </Text>
        </Group>
      )}
      <br />

      <Group mt="lg" justify="space-between" bg="imagine-green.0" p={10} style={{ borderRadius: '10px' }}>
        <Text size="md" pr={20}>
          External Care Team
        </Text>
        <ManageCareTeamIconAction
          patientId={patient!.Patient!.id!}
          internalCareTeamId={internalCareTeam?.id}
          externalCareTeamId={externalCareTeam?.id}
          show={ShowOptions.Both}
          refetch={refetch}
        >
          <IconCirclePlus size={20} />
        </ManageCareTeamIconAction>
      </Group>
      {hasExternalCareTeam && (
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Role</Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th>Association</Table.Th>
              <Table.Th>Contact</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{externalRows}</Table.Tbody>
        </Table>
      )}
      {!hasExternalCareTeam && (
        <Group mt={20} align="center" gap={7}>
          <ThemeIcon color="status-info">
            <IconInfoCircle size={20} />
          </ThemeIcon>
          <Text c="dimmed" fz="xs">
            This patient has not been assigned any external care team members.
          </Text>
        </Group>
      )}
    </>
  );
};
