import { PreferredLanguage } from '../codeSystems/ImaginePediatrics';
export interface LanguageTranslation {
  languageCodes: string[];
  value: string;
}
export const companyName: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: 'Imagine Pediatrics',
  },
];

export const appDownloadSms: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value:
      'Hello! Please download the Imagine Pediatrics app here: https://mobile.imaginepediatrics.org/download or search "Imagine Pediatrics" in your app store.',
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value:
      '¡Hola! Por favor descargue la aplicación Imagine Pediatrics aquí: https://mobile.imaginepediatrics.org/download o busque "Imagine Pediatrics" en su tienda de aplicaciones.',
  },
];

export const temporaryCredentialsSms: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: `Your Imagine Pediatrics one-time passcode is {password}. If you didn't request this, please contact us at 833-208-7770.`,
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value:
      'Su contraseña única de Imagine Pediatrics es {password}. Si no solicitó esto, comuníquese con nosotros al 833-208-7770.',
  },
];

export const incomingVideoCallNotification: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: 'Incoming Video Call',
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value: 'Llamada de video entrante',
  },
];

export const newDocumentNotification: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: 'New Document Available',
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value: 'Nuevo documento disponible',
  },
];

export const newMessageNotification: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: 'New Message',
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value: 'Nuevo mensaje',
  },
];

export const childDisenrolledNotification: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value:
      'Your child is no longer enrolled with Imagine Pediatrics. If you think this was a mistake or you have any questions, please call us.',
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value:
      'Su hijo ya no está inscrito en Imagine Pediatrics. Si cree que esto fue un error o tiene alguna pregunta, llámenos.',
  },
];

export const testMessage: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: 'This is a test message.',
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value: 'Este es un mensaje de prueba.',
  },
];

export const welcomeMessage: LanguageTranslation[] = [
  {
    languageCodes: [
      PreferredLanguage.En,
      PreferredLanguage.EnUS,
      PreferredLanguage.EnAU,
      PreferredLanguage.EnCA,
      PreferredLanguage.EnGB,
      PreferredLanguage.EnIN,
      PreferredLanguage.EnNZ,
      PreferredLanguage.EnSG,
    ],
    value: `Welcome to Imagine Pediatrics! We are proud to be a part of your child's team and look forward to helping you in their care and well-being. Call or message us anytime of the day or night with your questions or concerns. Our pediatrician-led care team is here to help, 24/7, right from home.`,
  },
  {
    languageCodes: [PreferredLanguage.Es, PreferredLanguage.EsAR, PreferredLanguage.EsES, PreferredLanguage.EsUY],
    value:
      '¡Bienvenido a Imagine Pediatrics! Estamos orgullosos de ser parte del equipo de su hijo y esperamos poder ayudarle en su cuidado y bienestar. Llámenos o envíenos un mensaje en cualquier momento de día o de noche con sus preguntas o preocupaciones. Nuestro equipo de atención dirigido por pediatras está aquí para ayudar, las 24 horas del día, los 7 días de la semana, desde su hogar.',
  },
];
