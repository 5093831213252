import { useGlobalChannelSubscription } from '@components/PusherProvider';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GetTasksQuery, useGetTasksQuery } from 'medplum-gql';
import { logError } from '@/errors';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { compact, map } from 'lodash';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { Patient, Practitioner, Reference } from '@medplum/fhirtypes';
import { TaskType } from 'const-utils';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { isDefined } from 'imagine-dsl/utils/lists';
import { useSearchParams } from 'react-router-dom';
import { usePagination } from '@/design-system/hooks/usePagination';
import { createReference } from '@medplum/core';
import { generateTaskFilter } from 'imagine-dsl/utils/task';

type SearchFilters = {
  patient?: Reference<Patient>;
  taskType?: string;
  assignedTo?: Reference<Practitioner> | 'unassigned';
  market?: string;
  task?: string;
  dueDateStart?: Date;
  dueDateEnd?: Date;
};

interface TaskAlertContext {
  loading: boolean;
  error?: ApolloError;
  refetchTasks: () => Promise<ApolloQueryResult<GetTasksQuery>>;
  tasks: BaseTask[];
  filters: SearchFilters;
  setFilters: (filters: SearchFilters) => void;
  selectedTaskId?: string;
  setSelectedTaskId: (id?: string) => void;
  selectedTask?: BaseTask;
  totalTasks: number;
  resolvedTasks: boolean;
  setResolvedTasks: (state: boolean) => void;
}

const TaskAlertContextValue = createContext<TaskAlertContext | undefined>(undefined);

export const useTaskAlertContext = (): TaskAlertContext => {
  const context = useContext(TaskAlertContextValue);
  if (!context) {
    throw new Error('useTaskAlertContext must be used within a TaskAlertProvider');
  }
  return context;
};

const PAGE_SIZE = 10;

export function TaskAlertProvider({ children }: React.PropsWithChildren): JSX.Element {
  const { markets, profile } = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resolvedTasks, setResolvedTasks] = useState(false);

  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>(searchParams.get('task') ?? undefined);
  const [selectedTask, setSelectedTask] = useState<BaseTask | undefined>(undefined);

  const initializedRef = useRef(false);
  useEffect(() => {
    if (!profile || initializedRef.current) {
      return;
    }
    initializedRef.current = true;

    if (!searchParams.get('task')) {
      setSelectedTaskId(undefined);
      setSelectedTask(undefined);
    }

    if (!searchParams.get('assignedTo') && !selectedTaskId) {
      if (profile) {
        searchParams.set('assignedTo', createReference(profile).reference!);
      }
      setSearchParams(searchParams);
    }
  }, [setSearchParams, searchParams, profile, selectedTaskId]);

  const patientRef = searchParams.get('patient') || '';
  const taskType = searchParams.get('taskType') || '';
  const assignedTo = searchParams.get('assignedTo') || '';
  const defaultMarketId = markets.length === 1 ? markets[0].id : '';
  const market = searchParams.get('market') || defaultMarketId || '';
  const dueDateStart = searchParams.get('dueDateStart') || '';
  const dueDateEnd = searchParams.get('dueDateEnd') || '';

  const setFilters = useCallback(
    (f: SearchFilters) => {
      setSearchParams((params) => {
        Object.entries(f).forEach(([key, value]) => {
          if (!value) {
            params.delete(key);
          } else if (typeof value !== 'string') {
            if (key === 'dueDateStart' || key === 'dueDateEnd') {
              params.set(key, (value as Date).toISOString());
            } else if ('reference' in value) {
              params.set(key, value.reference ?? '');
            } else {
              params.set(key, '');
            }
          } else {
            params.set(key, value);
          }
        });

        return params;
      });
    },
    [setSearchParams],
  );

  const defaultTaskTags = useMemo(() => {
    const tags = [
      TaskType.CarePathwayReferralReview.toString(),
      TaskType.BHTOC.toString(),
      TaskType.ScreenerReview.toString(),
      TaskType.ReviewBHSurvey.toString(),
      TaskType.ReviewDisasterPreparednessSurvey.toString(),
      'outreach',
    ];
    return tags;
  }, []);

  const filters: SearchFilters = useMemo(() => {
    let at: SearchFilters['assignedTo'] = undefined;
    if (assignedTo === 'unassigned') {
      at = 'unassigned';
    } else if (assignedTo !== undefined && assignedTo !== 'unassigned') {
      at = { reference: assignedTo };
    }
    return {
      patient: { reference: patientRef },
      taskType: taskType,
      assignedTo: at,
      market,
      dueDateStart: dueDateStart ? new Date(dueDateStart) : undefined,
      dueDateEnd: dueDateEnd ? new Date(dueDateEnd) : undefined,
    };
  }, [patientRef, taskType, assignedTo, market, dueDateStart, dueDateEnd]);

  const marketsString = useMemo(
    () => (filters.market ? [{ id: filters.market }] : markets).map((m) => `Organization/${m.id}`).join(','),
    [filters.market, markets],
  );

  const taskTags = useMemo(() => filters.taskType || defaultTaskTags.join(','), [filters.taskType, defaultTaskTags]);

  const pagination = usePagination({});
  const offset = useMemo(() => (pagination.currentPage - 1) * PAGE_SIZE, [pagination.currentPage]);

  const ownerFilter = useMemo(() => {
    if (filters.assignedTo === 'unassigned') {
      return ' and owner pr false';
    } else if (filters.assignedTo?.reference) {
      return ` and owner re ${filters.assignedTo.reference}`;
    }
    return '';
  }, [filters.assignedTo]);

  /*
    We're intentionally leaving out the pod filtering due to query time taking an order of magnitude longer
    The additional query for pod looks something along these lines:
    'patient._has:CareTeam:subject:participant': '${pod-list}',

    There is an active thread with Medplum and we may revisit adding in the future if they can optimize the query
    https://imaginepediatrics.atlassian.net/browse/IP-2389?focusedCommentId=52276
  */

  const {
    data: taskData,
    loading: tasksLoading,
    refetch: refetchTasks,
  } = useGetTasksQuery({
    variables: {
      tag: taskTags,
      filter: generateTaskFilter(
        resolvedTasks ? 'complete' : 'incomplete',
        filters.patient?.reference || '',
        marketsString,
        ownerFilter,
        filters.dueDateStart?.toISOString() || '',
        filters.dueDateEnd?.toISOString() || '',
      ),

      count: PAGE_SIZE,
      offset: offset,
    },
    skip: !taskTags || !marketsString,
  });

  const tasks = useMemo(() => {
    const cpTasks = compact(taskData?.TaskConnection?.edges || [])
      .map((t) => t.resource)
      .filter(isDefined)
      .map((t) => new BaseTask(t));

    return cpTasks
      .filter((t) => map(markets, 'id').includes((t.for as Patient).managingOrganization?.resource?.id))
      .sort((a, b) => {
        if (a.dueDate && !b.dueDate) {
          return -1;
        }
        if (!a.dueDate && b.dueDate) {
          return 1;
        }

        if (a.dueDate && b.dueDate) {
          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        }
        return 0;
      });
  }, [taskData, markets]);

  useGlobalChannelSubscription('new-unassigned-survey-review', (_) => {
    refetchTasks().catch((e) => logError(e));
  });

  return (
    <TaskAlertContextValue.Provider
      value={{
        loading: tasksLoading,
        refetchTasks,
        tasks,
        filters,
        setFilters,
        selectedTaskId,
        setSelectedTaskId,
        selectedTask,
        totalTasks: taskData?.TaskConnection?.count ?? 0,
        resolvedTasks,
        setResolvedTasks,
      }}
    >
      {children}
    </TaskAlertContextValue.Provider>
  );
}
